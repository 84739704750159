var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "deactivate-account-container" }, [
        _c("div", { staticClass: "deactivate-card" }, [
          _c(
            "button",
            {
              staticClass: "grupa-red-btn",
              on: {
                click: function ($event) {
                  _vm.isModal = true
                },
              },
            },
            [_vm._v("\n        Deactivate My Account\n      ")]
          ),
        ]),
      ]),
      _vm.isModal
        ? _c(
            "DeactivateModal",
            _vm._b(
              { attrs: { handleDelete: _vm.deactivateUserAccount } },
              "DeactivateModal",
              { spinner: _vm.spinner, closeDialog: _vm.closeDialog },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }