var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [
          _vm._v(_vm._s(_vm.dialogeTitle)),
        ]),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "delete-item-msg" }, [
          _vm._v("\n        " + _vm._s(_vm.dialogeBodyLabel) + "\n      "),
        ]),
        _c("div", { staticClass: "delete-item-prompt" }, [
          _vm._v("Do you wish to continue?"),
        ]),
      ]),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "button",
          { staticClass: "grupa-red-btn", on: { click: _vm.closeDialog } },
          [_vm._v("\n        " + _vm._s(_vm.dialogeNoLabel) + "\n      ")]
        ),
        !_vm.spinner
          ? _c(
              "button",
              {
                staticClass: "grupa-grey-btn",
                staticStyle: { "margin-right": "24px" },
                on: { click: _vm.handleDelete },
              },
              [_vm._v("\n        " + _vm._s(_vm.dialogeYesLabel) + "\n      ")]
            )
          : _vm._e(),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.spinner,
              expression: "spinner",
            },
          ],
          staticClass: "button-spinner",
          staticStyle: {
            "margin-left": "20px",
            width: "20px",
            height: "20px",
            "margin-top": "10px",
          },
          attrs: { src: "/img/lightbox/preloader.gif" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }