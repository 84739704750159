var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogComponent",
    {
      attrs: {
        dialogTitle: "Edit Team Role & Skills",
        closeDialog: _vm.closeDialog,
      },
      scopedSlots: _vm._u([
        {
          key: "dialog-footer",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "grupa-white-btn",
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "grupa-blue-btn",
                  staticStyle: { "margin-left": "8px" },
                  on: { click: _vm.handleUpdateFramework },
                },
                [
                  _vm._v("\n      Update\n      "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.spinner,
                        expression: "spinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "modal-contents", attrs: { id: "main-children" } },
        [
          _c("div", { staticClass: "modal-instruction" }, [
            _vm._v(
              "\n      Edit your personal and technical skills. To change your designated team\n      role, contact your team lead\n    "
            ),
          ]),
          _c("div", { staticClass: "edit-form" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.stackForm.team_role,
                  expression: "stackForm.team_role",
                },
              ],
              staticClass: "form-control",
              attrs: { placeholder: "Full name", type: "text", disabled: "" },
              domProps: { value: _vm.stackForm.team_role },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.stackForm, "team_role", $event.target.value)
                },
              },
            }),
            _c(
              "div",
              [
                _c("v-combobox", {
                  staticClass: "vuetify-combo language_combo",
                  attrs: {
                    id: "languages",
                    items: _vm.tools_list,
                    "item-value": "tools_list.name",
                    flat: "",
                    "item-text": "name",
                    multiple: "",
                    "persistent-hint": "",
                    "small-chips": "",
                    solo: "",
                    placeholder: "select languages",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateStack("languages")
                    },
                  },
                  model: {
                    value: _vm.tempArray.tools,
                    callback: function ($$v) {
                      _vm.$set(_vm.tempArray, "tools", $$v)
                    },
                    expression: "tempArray.tools",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("v-combobox", {
                  staticClass: "vuetify-combo",
                  attrs: {
                    id: "framework",
                    items: _vm.framework,
                    "item-value": "framework.name",
                    "item-text": "name",
                    flat: "",
                    multiple: "",
                    "persistent-hint": "",
                    "small-chips": "",
                    solo: "",
                    placeholder: "select tools",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateStack("framework")
                    },
                  },
                  model: {
                    value: _vm.tempArray.dev_framework,
                    callback: function ($$v) {
                      _vm.$set(_vm.tempArray, "dev_framework", $$v)
                    },
                    expression: "tempArray.dev_framework",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("v-combobox", {
                  staticClass: "vuetify-combo",
                  attrs: {
                    id: "database",
                    items: _vm.db_tools,
                    "item-value": "db_tools.name",
                    flat: "",
                    "item-text": "name",
                    hint: "seperate each item with tab key",
                    label: "Tools and languages",
                    multiple: "",
                    "persistent-hint": "",
                    "small-chips": "",
                    solo: "",
                    placeholder: "select tools",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateStack("database")
                    },
                  },
                  model: {
                    value: _vm.tempArray.dev_database,
                    callback: function ($$v) {
                      _vm.$set(_vm.tempArray, "dev_database", $$v)
                    },
                    expression: "tempArray.dev_database",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }