<template>
  <div class="education-history">
    <div class="card-head">
      <div class="card-title">education</div>
      <div class="card-action" @click="isDialog = true">Add Education</div>
    </div>

    <div v-if="educationData.length == 0" class="empty-education">
      <div>
        <img src="/img/dashboard/colleague-hat.svg" alt="" />
      </div>
      <div class="education-instruction">
        Add schools you have attended and relevant degrees you have obtained.
      </div>
    </div>
    <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
      <div class="education-list" v-if="educationData.length > 0">
        <div
          class="education-card"
          v-for="(school, index) in educationData"
          :key="index"
        >
          <div class="education-title">{{ school.degree_title }}</div>
          <div class="school-name">{{ school.institution }}</div>
          <div class="school-duration-range">
            <div class="school-date-rage">
              <span v-if="school.start_date">
                {{ $moment(school.start_date).format("YYYY") }}
              </span>
              -
              <span v-if="school.end_date">
                {{ $moment(school.end_date).format("YYYY") }}
              </span>
            </div>
            <div class="remove-button"></div>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <AddEducationModal
      v-if="isDialog"
      v-bind="{
        form,
        form_errors,
        closeDialog,
        spinner,
        validateUserEducation,
      }"
    />
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions } from "vuex";
import { devAddEducation } from "@/api/team";
import AddEducationModal from "./modals/addEducationModal";
export default {
  props: {
    devEducationList: {
      required: true,
      type: Array,
    },
  },
  components: {
    AddEducationModal,
    VuePerfectScrollbar,
  },
  data: () => ({
    isDialog: false,
    form: {
      title: "",
      school: "",
      school_location: "",
      start_date: "",
      end_date: "",
      startMenu: false,
      endMenu: false,
    },
    educationData: [],
    form_errors: {
      title: "",
      school: "",
      school_location: "",
      start_date: "",
      end_date: "",
    },
    spinner: false,
    settings: {
      maxScrollbarLength: 60,
    },
  }),

  created() {},
  mounted() {
    // alert('jj')
    this.educationData = this.devEducationList;
    // console.log(this.educationData)
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    closeDialog() {
      this.isDialog = false;
    },
    validateUserEducation() {
      if (this.form.title == "") {
        this.form_errors.title = "Degree title is required";
      } else if (this.form.school == "") {
        this.form_errors.school = "School name is required";
      } else if (this.form.school_location == "") {
        this.form_errors.school_location = "School location is required";
      } else if (this.form.start_date == "") {
        this.form_errors.start_date = "Start date is required";
      } else if (this.form.end_date == "" && !this.form.current_job) {
        this.form_errors.end_date = "End date is required";
      } else {
        this.handleAddEducation();
      }
    },
    handleAddEducation() {
      this.spinner = true;
      let { title, school, school_location, start_date, end_date } = this.form;
      let payload = {
        degree_title: title,
        institution: school,
        location: school_location,
        start_date,
        end_date,
      };

      devAddEducation(payload)
        .then((response) => {
          if (response.data.status == 1) {
            this.spinner = false;
            //   this.addSpinner = false;
            console.log(response.data.data);
            if (Object.keys(this.educationData).length > 0) {
              this.educationData = this.educationData.concat(
                response.data.data
              );
            } else {
              this.educationData = response.data.data;
            }

            // this.educationData = this.devEducationList;
            this.setSuccessMsg("Education added successfully");
            this.isDialog = false;
            //   if (addMore !== 1) {
            //     this.isDialog = false;
            //   }
          }
        })
        .catch((err) => {
          this.spinner = false;
          console.log(err);
          this.setErrorMsg(`An error occurred please try again`);
          // this.addSpinner = false;
          // if (err.response.data.status == 0) {
          //   this.response_err = err.response.data.msg;

          //   this.$developConsole(err.response);
          // } else {
          //   this.setErrorMsg("An error occurred please try again");
          //   this.$developConsole(err.response);
          // }
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.education-history {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px 12px;
  width: 50%;
  margin-left: 16px;
  min-height: 350px;
}
.card-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0px 12px;
}
.card-title {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
}
.card-action {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  cursor: pointer;
}
.empty-education {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100% - 17px);
}
.education-instruction {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  width: 362px;
}
.education-list .education-card:nth-of-type(1) {
  margin-top: 12px;
}
.education-list {
  height: 330px;
  padding: 0px 12px;
}
.education-card {
  margin-top: 28px;
  text-align: left;
}
.education-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.school-name {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
  margin-top: 2px;
}
.school-duration-range {
  display: flex;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  justify-content: space-between;
}
</style>
