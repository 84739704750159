var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogComponent",
    {
      attrs: { dialogTitle: "Change password", closeDialog: _vm.closeDialog },
      scopedSlots: _vm._u([
        {
          key: "dialog-footer",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "grupa-white-btn",
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "grupa-blue-btn",
                  staticStyle: { "margin-left": "8px" },
                  on: { click: _vm.handleChangePassword },
                },
                [
                  _vm._v("\n      Update\n      "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.spinner,
                        expression: "spinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "modal-contents", attrs: { id: "main-children" } },
        [
          _c("div", { staticClass: "modal-instruction" }, [
            _vm._v(
              "\n      To change your current password, enter the current password and your new\n      password.\n    "
            ),
          ]),
          _c("div", { staticClass: "edit-form" }, [
            _c("div", { staticClass: "form-error" }, [
              _vm.form_errors.current_password
                ? _c("span", { staticClass: "error_span" }, [
                    _vm._v(_vm._s(_vm.form_errors.current_password[0])),
                  ])
                : _vm._e(),
            ]),
            _vm.type === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.current_password,
                      expression: "form.current_password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder: "Enter current password",
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.form.current_password)
                      ? _vm._i(_vm.form.current_password, null) > -1
                      : _vm.form.current_password,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.current_password,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "current_password",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "current_password",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "current_password", $$c)
                      }
                    },
                  },
                })
              : _vm.type === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.current_password,
                      expression: "form.current_password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder: "Enter current password",
                    type: "radio",
                  },
                  domProps: {
                    checked: _vm._q(_vm.form.current_password, null),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.form, "current_password", null)
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.current_password,
                      expression: "form.current_password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder: "Enter current password",
                    type: _vm.type,
                  },
                  domProps: { value: _vm.form.current_password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.form,
                        "current_password",
                        $event.target.value
                      )
                    },
                  },
                }),
            _c("div", { staticClass: "form-error" }, [
              _vm.form_errors.new_password
                ? _c("span", { staticClass: "error_span" }, [
                    _vm._v(_vm._s(_vm.form_errors.new_password[0])),
                  ])
                : _vm._e(),
            ]),
            _vm.type === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.new_password,
                      expression: "form.new_password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder: "Enter new password",
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.form.new_password)
                      ? _vm._i(_vm.form.new_password, null) > -1
                      : _vm.form.new_password,
                  },
                  on: {
                    blur: _vm.validatePassword,
                    change: function ($event) {
                      var $$a = _vm.form.new_password,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "new_password",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "new_password",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "new_password", $$c)
                      }
                    },
                  },
                })
              : _vm.type === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.new_password,
                      expression: "form.new_password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Enter new password", type: "radio" },
                  domProps: { checked: _vm._q(_vm.form.new_password, null) },
                  on: {
                    blur: _vm.validatePassword,
                    change: function ($event) {
                      return _vm.$set(_vm.form, "new_password", null)
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.new_password,
                      expression: "form.new_password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Enter new password", type: _vm.type },
                  domProps: { value: _vm.form.new_password },
                  on: {
                    blur: _vm.validatePassword,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "new_password", $event.target.value)
                    },
                  },
                }),
            _c("div", { staticClass: "form-error" }, [
              _vm.form_errors.repeat_password
                ? _c("span", { staticClass: "error_span" }, [
                    _vm._v(_vm._s(_vm.form_errors.repeat_password[0])),
                  ])
                : _vm._e(),
            ]),
            _vm.type === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.repeat_password,
                      expression: "form.repeat_password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder: "Enter new password again",
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.form.repeat_password)
                      ? _vm._i(_vm.form.repeat_password, null) > -1
                      : _vm.form.repeat_password,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.repeat_password,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "repeat_password",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "repeat_password",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "repeat_password", $$c)
                      }
                    },
                  },
                })
              : _vm.type === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.repeat_password,
                      expression: "form.repeat_password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder: "Enter new password again",
                    type: "radio",
                  },
                  domProps: { checked: _vm._q(_vm.form.repeat_password, null) },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.form, "repeat_password", null)
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.repeat_password,
                      expression: "form.repeat_password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder: "Enter new password again",
                    type: _vm.type,
                  },
                  domProps: { value: _vm.form.repeat_password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "repeat_password", $event.target.value)
                    },
                  },
                }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }