var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogComponent",
    {
      attrs: {
        dialogTitle: "Edit Personal Info",
        closeDialog: _vm.closeDialog,
      },
      scopedSlots: _vm._u([
        {
          key: "dialog-footer",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "grupa-white-btn",
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "grupa-blue-btn",
                  staticStyle: { "margin-left": "8px" },
                  on: { click: _vm.handleUpdateData },
                },
                [
                  _vm._v("\n      Update\n      "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.spinner,
                        expression: "spinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "modal-contents", attrs: { id: "main-children" } },
        [
          _c("div", { staticClass: "modal-instruction" }, [
            _vm._v(
              "\n      Edit your full name, phone number, location and personal bio. You cannot\n      change your email address at this time.\n    "
            ),
          ]),
          _c("div", { staticClass: "edit-form" }, [
            _c("div", { staticClass: "form-error" }, [
              _vm.form_errors.name
                ? _c("span", { staticClass: "error_span" }, [
                    _vm._v(_vm._s(_vm.form_errors.name[0])),
                  ])
                : _vm._e(),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name",
                },
              ],
              staticClass: "form-control",
              attrs: { placeholder: "Full name", type: "text" },
              domProps: { value: _vm.form.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name", $event.target.value)
                },
              },
            }),
            _c("div", { staticClass: "form-error" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.email,
                  expression: "form.email",
                },
              ],
              staticClass: "form-control",
              attrs: {
                placeholder: "Email Address",
                type: "email",
                disabled: "",
              },
              domProps: { value: _vm.form.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "email", $event.target.value)
                },
              },
            }),
            _c("div", { staticClass: "form-error" }, [
              _vm.form_errors.phone_number
                ? _c("span", { staticClass: "error_span" }, [
                    _vm._v(_vm._s(_vm.form_errors.phone_number[0])),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "selectPhoneNumber" },
              [
                _c(
                  "vue-tel-input",
                  _vm._b(
                    {
                      attrs: { placeholder: "enter mobile phone number" },
                      on: {
                        "country-changed": _vm.countryChange,
                        validate: _vm.validationMethod,
                        input: _vm.validatePhoneNumber,
                      },
                      model: {
                        value: _vm.form.phone_number,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "phone_number", $$v)
                        },
                        expression: "form.phone_number",
                      },
                    },
                    "vue-tel-input",
                    _vm.bindProps,
                    false
                  )
                ),
              ],
              1
            ),
            _c("div", { staticClass: "form-error" }, [
              _vm.form_errors.bio
                ? _c("span", { staticClass: "error_span" }, [
                    _vm._v(_vm._s(_vm.form_errors.bio[0])),
                  ])
                : _vm._e(),
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.bio,
                  expression: "form.bio",
                },
              ],
              staticClass: "form-control",
              staticStyle: { height: "unset" },
              attrs: {
                name: "overview",
                rows: "4",
                id: "textarea",
                placeholder: "Write a short note about yourself",
              },
              domProps: { value: _vm.form.bio },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "bio", $event.target.value)
                },
              },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }