<template>
  <DialogComponent
    dialogTitle="Add Work Experience"
    v-bind:closeDialog="closeDialog"
  >
    <div id="main-children" class="modal-contents">
      <div class="modal-instruction">
        Add details of relevant work experiences you have. We will order the
        list starting with the most recent
      </div>
      <div class="edit-form">
        <div class="form-error">
          <span class="error_span">{{ form_errors.role }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.role"
          placeholder="Enter job title"
          type="text"
          @input="form_errors.role = ''"
        />
        <div class="form-error">
          <span class="error_span">{{ form_errors.organization }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.organization"
          placeholder="enter name of company"
          @input="form_errors.organization = ''"
          type="text"
        />
        <div class="form-error">
          <span class="error_span">{{ form_errors.website }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.website"
          placeholder="https://companywebsite.comy"
          @input="form_errors.website = ''"
          type="text"
        />
        <div class="form-error">
          <span class="error_span">{{ form_errors.company_location }}</span>
        </div>
        <vue-google-autocomplete
          ref="autocomplete"
          id="map"
          classname="form-control addressTextBox"
          placeholder="state, country"
          v-on:placechanged="getAddressData"
          @input="form_errors.company_location = ''"
          types=""
        >
        </vue-google-autocomplete>
        <div class="form-error">
          <span class="error_span">{{ form_errors.start_date }}</span>
        </div>
        <div class="start-date-wrapper">
          <v-menu
            ref="element"
            v-model="form.startMenu"
            :close-on-content-click="false"
            :return-value.sync="form.start_date"
          >
            <div class="input_wrap" slot="activator">
              <input
                type="text"
                v-model="form.start_date"
                class="date_textbox"
                placeholder="Enter start date"
                @input="form_errors.start_date = ''"
              />
              <img src="/img/onboarding/calendar.svg" />
            </div>
            <v-date-picker
              v-model="form.start_date"
              no-title
              scrollable
              :max="todayDate"
              type="date"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="form.startMenu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.element.save(form.start_date)"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="form-error">
          <span class="error_span">{{ form_errors.end_date }}</span>
        </div>
        <div class="start-date-wrapper">
          <v-menu
            ref="end"
            v-model="form.endMenu"
            :close-on-content-click="false"
            :return-value.sync="form.end_date"
          >
            <div
              class="input_wrap"
              :style="form.current_job ? 'background: #CDCDCD;' : null"
              slot="activator"
            >
              <input
                type="text"
                v-model="form.end_date"
                class="date_textbox"
                placeholder="Enter end date"
                @input="form_errors.end_date = ''"
                :disabled="form.current_job"
              />
              <img src="/img/onboarding/calendar.svg" />
            </div>
            <v-date-picker
              v-if="!form.current_job"
              v-model="form.end_date"
              :max="todayDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="form.endMenu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.end.save(form.end_date)"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div
          @click="form.current_job = !form.current_job"
          style="text-align: left; cursor: pointer; margin-top: 16px"
        >
          <span style="margin-right: 8px">
            <img v-show="form.current_job" src="/img/onboarding/check.svg" />
            <img v-show="!form.current_job" src="/img/onboarding/uncheck.svg" />
          </span>
          <span style="color: #15171a; font-weight: normal; font-size: 14px"
            >I currently work here</span
          >
        </div>
      </div>
    </div>
    <template v-slot:dialog-footer>
      <button
        class="grupa-white-blue-btn space-btn"
        @click="validateWorkExperience(1)"
      >
        Add Another
        <img
          src="/img/lightbox/preloader.gif"
          style="margin-left: 20px"
          class="button-spinner"
          v-show="addSpinner"
        />
      </button>
      <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
      <button
        @click="validateWorkExperience(0)"
        style="margin-left: 8px"
        class="grupa-blue-btn"
      >
        Save
        <img
          src="/img/lightbox/preloader.gif"
          style="margin-left: 20px"
          class="button-spinner"
          v-show="spinner"
        />
      </button>
    </template>
  </DialogComponent>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import DialogComponent from "@/components/Modals/dialogComponent";
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    form_errors: {
      type: Object,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
    addSpinner: {
      type: Boolean,
      required: true,
    },
    validateWorkExperience: {
      type: Function,
      required: true,
    },
    getAddressData: {
      type: Function,
      required: true,
    },
    setUserLocation: {
      type: Function,
      required: true,
    },
  },
  components: {
    DialogComponent,
    VueGoogleAutocomplete,
  },

  data: () => ({
    refId: "menu",
    startMenu: false,
    todayDate: new Date().toISOString().slice(0, 10),
  }),

  mounted() {
    this.setUserLocation();
  },
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.modal-contents {
  width: 356px;
  text-align: left;
}
.modal-instruction {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.edit-form {
  margin-top: 8px;
}
.start-date-wrapper,
.form-control {
  margin-bottom: 0;
  margin-top: 1px;
}
.date_textbox {
  width: 84%;
}
.input_wrap {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 4px;
  display: flex;
  padding: 15px 12px 12px 16px;
  height: 50px;
  width: 100%;
  justify-content: space-between;
}
.date_textbox:focus {
  border: 0px;
  outline: none;
}
.space-btn {
  margin-right: 72px;
}
.form-error {
  padding-top: 4px;
  color: red;
  font-size: 12px;
  height: 18px;
}
</style>
<style>
.start-date-wrapper .v-menu--inline {
  width: 100%;
}
</style>