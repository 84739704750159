<template>
  <DialogComponent
    dialogTitle="Change password"
    v-bind:closeDialog="closeDialog"
  >
    <div id="main-children" class="modal-contents">
      <div class="modal-instruction">
        To change your current password, enter the current password and your new
        password.
      </div>
      <div class="edit-form">
        <div class="form-error">
          <span class="error_span" v-if="form_errors.current_password">{{
            form_errors.current_password[0]
          }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.current_password"
          placeholder="Enter current password"
          :type="type"
        />
        <div class="form-error">
          <span class="error_span" v-if="form_errors.new_password">{{
            form_errors.new_password[0]
          }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.new_password"
          placeholder="Enter new password"
          :type="type"
          @blur="validatePassword"
        />
        <div class="form-error">
          <span class="error_span" v-if="form_errors.repeat_password">{{
            form_errors.repeat_password[0]
          }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.repeat_password"
          placeholder="Enter new password again"
          :type="type"
        />
      </div>
    </div>
    <template v-slot:dialog-footer>
      <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
      <button
        style="margin-left: 8px"
        @click="handleChangePassword"
        class="grupa-blue-btn"
      >
        Update
        <img
          src="/img/lightbox/preloader.gif"
          style="margin-left: 20px"
          class="button-spinner"
          v-show="spinner"
        />
      </button>
    </template>
  </DialogComponent>
</template>

<script>
import { devChangePassword } from "@/api/team";
import { mapActions } from "vuex";
import DialogComponent from "@/components/Modals/dialogComponent";
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
  },
  components: {
    DialogComponent,
  },

  data: () => ({
    spinner: false,
    type: "password",
    form: {
      current_password: "",
      new_password: "",
      repeat_password: "",
    },
    form_errors: {
      current_password: "",
      new_password: "",
      repeat_password: "",
    },
  }),

  created() {},
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    handleChangePassword() {
      this.form_errors = {
        current_password: [],
        new_password: [],
        repeat_password: [],
      };
      if (!this.form.current_password) {
        this.form_errors.current_password[0] =
          "Current password cannot be empty";
      }
      if (!this.form.new_password) {
        this.form_errors.new_password[0] = "New password cannot be Empty";
      }
      if (!this.form.repeat_password) {
        this.form_errors.repeat_password[0] =
          "You must re-type your new password";
      }
      if (this.form.new_password != this.form.repeat_password) {
        this.form_errors.repeat_password[0] = "Password does not match";
      }
      if (this.form.new_password.length < 8) {
        this.form_errors.new_password[0] =
          "New Password must be up to 8 character";
      }
      if (
        (this.form_errors.current_password.length == 0 &&
          this.form_errors.new_password.length,
        this.form_errors.repeat_password.length == 0)
      ) {
        let { current_password, new_password } = this.form;
        let passwordData = {
          password: current_password,
          new_password: new_password,
        };
        this.changePassword(passwordData);
      }
    },
    validatePassword() {
      if (this.form.new_password.length < 8) {
        this.setErrorMsg("New Password must be up to 8 character");
      }
    },

    changePassword(passwordData) {
      this.spinner = true;
      devChangePassword(passwordData)
        .then((response) => {
          if (response.data.status == 1) {
            this.spinner = false;
            this.setSuccessMsg("Your Password Was Changed Successfully");
            this.closeDialog();
          } else if (response.data.status == 0) {
            this.spinner = false;
            this.setErrorMsg(response.data.msg);
          }
        })
        .catch((error) => {
          this.$developConsole(error.response);
          this.spinner = false;
          if (error.response.data.status == 0) {
            this.$developConsole(error.response.data.errors.password);
            this.setErrorMsg("Invalid current password");
          } else {
            this.setErrorMsg("Error in network connection");
          }
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.modal-contents {
  width: 356px;
  text-align: left;
}
.modal-instruction {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.edit-form {
  margin-top: 8px;
}
.form-control {
  margin-bottom: 0;
  margin-top: 1px;
}
.form-error {
  padding-top: 4px;
  color: red;
  font-size: 12px;
  height: 18px;
}
</style>
