var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "education-history" },
    [
      _c("div", { staticClass: "card-head" }, [
        _c("div", { staticClass: "card-title" }, [_vm._v("education")]),
        _c(
          "div",
          {
            staticClass: "card-action",
            on: {
              click: function ($event) {
                _vm.isDialog = true
              },
            },
          },
          [_vm._v("Add Education")]
        ),
      ]),
      _vm.educationData.length == 0
        ? _c("div", { staticClass: "empty-education" }, [
            _vm._m(0),
            _c("div", { staticClass: "education-instruction" }, [
              _vm._v(
                "\n      Add schools you have attended and relevant degrees you have obtained.\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "VuePerfectScrollbar",
        {
          staticClass: "perfect-scroll-area",
          attrs: { settings: _vm.settings },
        },
        [
          _vm.educationData.length > 0
            ? _c(
                "div",
                { staticClass: "education-list" },
                _vm._l(_vm.educationData, function (school, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "education-card" },
                    [
                      _c("div", { staticClass: "education-title" }, [
                        _vm._v(_vm._s(school.degree_title)),
                      ]),
                      _c("div", { staticClass: "school-name" }, [
                        _vm._v(_vm._s(school.institution)),
                      ]),
                      _c("div", { staticClass: "school-duration-range" }, [
                        _c("div", { staticClass: "school-date-rage" }, [
                          school.start_date
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$moment(school.start_date)
                                        .format("YYYY")
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v("\n            -\n            "),
                          school.end_date
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$moment(school.end_date)
                                        .format("YYYY")
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "remove-button" }),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _vm.isDialog
        ? _c(
            "AddEducationModal",
            _vm._b(
              {},
              "AddEducationModal",
              {
                form: _vm.form,
                form_errors: _vm.form_errors,
                closeDialog: _vm.closeDialog,
                spinner: _vm.spinner,
                validateUserEducation: _vm.validateUserEducation,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: "/img/dashboard/colleague-hat.svg", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }