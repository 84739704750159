import { render, staticRenderFns } from "./profileDetails.vue?vue&type=template&id=d3c5d476&scoped=true&"
import script from "./profileDetails.vue?vue&type=script&lang=js&"
export * from "./profileDetails.vue?vue&type=script&lang=js&"
import style0 from "./profileDetails.vue?vue&type=style&index=0&id=d3c5d476&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3c5d476",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oeze/Documents/grupa/grupa-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d3c5d476')) {
      api.createRecord('d3c5d476', component.options)
    } else {
      api.reload('d3c5d476', component.options)
    }
    module.hot.accept("./profileDetails.vue?vue&type=template&id=d3c5d476&scoped=true&", function () {
      api.rerender('d3c5d476', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/DevDashboard/profile/profileDetails.vue"
export default component.exports