<template>
  <DialogComponent dialogTitle="Add Education" v-bind:closeDialog="closeDialog">
    <div id="main-children" class="modal-contents">
      <div class="modal-instruction">
        Add details of schools and relevant degrees you have obtained. We will
        order the list starting with the most recent
      </div>
      <div class="edit-form">
        <div class="form-error">
          <span class="error_span">{{ form_errors.title }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.title"
          placeholder="Enter degree title"
          type="text"
          @input="form_errors.title = ''"
        />
        <div class="form-error">
          <span class="error_span">{{ form_errors.school }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.school"
          placeholder="School name"
          type="text"
          @input="form_errors.school = ''"
        />
        <div class="form-error">
          <span class="error_span">{{ form_errors.school_location }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.school_location"
          placeholder="State, Country"
          type="text"
          @input="form_errors.school_location = ''"
        />
        <div class="form-error">
          <span class="error_span">{{ form_errors.start_date }}</span>
        </div>
        <div class="start-date-wrapper">
          <v-menu
            ref="element"
            v-model="form.startMenu"
            :close-on-content-click="false"
            :return-value.sync="form.start_date"
          >
            <div class="input_wrap" slot="activator">
              <input
                type="text"
                v-model="form.start_date"
                class="date_textbox"
                placeholder="Enter start date"
                @input="form_errors.start_date = ''"
              />
              <img src="/img/onboarding/calendar.svg" />
            </div>
            <v-date-picker
              v-model="form.start_date"
              no-title
              scrollable
              :max="todayDate"
              type="date"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="form.startMenu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.element.save(form.start_date)"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="form-error">
          <span class="error_span">{{ form_errors.end_date }}</span>
        </div>
        <div class="start-date-wrapper">
          <v-menu
            ref="end"
            v-model="form.endMenu"
            :close-on-content-click="false"
            :return-value.sync="form.end_date"
          >
            <div class="input_wrap" slot="activator">
              <input
                type="text"
                v-model="form.end_date"
                class="date_textbox"
                placeholder="Enter end date"
                @input="form_errors.end_date = ''"
              />
              <img src="/img/onboarding/calendar.svg" />
            </div>
            <v-date-picker
              v-model="form.end_date"
              :max="todayDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="form.endMenu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.end.save(form.end_date)"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div>
    </div>
    <template v-slot:dialog-footer>
      <button class="grupa-white-blue-btn space-btn" style="display:none">Add Another</button>
      <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
      <button
        @click="validateUserEducation"
        style="margin-left: 8px"
        class="grupa-blue-btn"
      >
        Save
        <img
          src="/img/lightbox/preloader.gif"
          style="margin-left: 20px"
          class="button-spinner"
          v-show="spinner"
        />
      </button>
    </template>
  </DialogComponent>
</template>

<script>
import DialogComponent from "@/components/Modals/dialogComponent";
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
    form_errors: {
      type: Object,
      required: true,
    },
    validateUserEducation: {
      type: Function,
      required: true,
    },
  },
  components: {
    DialogComponent,
  },

  data: () => ({
    refId: "menu",
    startMenu: false,
    todayDate: new Date().toISOString().slice(0, 10),
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.modal-contents {
  width: 356px;
  text-align: left;
}
.modal-instruction {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.edit-form {
  margin-top: 8px;
}
.start-date-wrapper,
.form-control {
  margin-bottom: 0;
  margin-top: 1px;
}

.date_textbox {
  width: 84%;
}
.input_wrap {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 4px;
  display: flex;
  padding: 15px 12px 12px 16px;
  height: 50px;
  width: 100%;
  justify-content: space-between;
}
.date_textbox:focus {
  border: 0px;
  outline: none;
}
.space-btn {
  margin-right: 72px;
}
.form-error {
  padding-top: 4px;
  color: red;
  font-size: 12px;
  height: 18px;
}
</style>
<style>
.start-date-wrapper .v-menu--inline {
  width: 100%;
}
</style>
