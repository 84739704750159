var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogComponent",
    {
      attrs: {
        dialogTitle: "Add Work Experience",
        closeDialog: _vm.closeDialog,
      },
      scopedSlots: _vm._u([
        {
          key: "dialog-footer",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "grupa-white-blue-btn space-btn",
                  on: {
                    click: function ($event) {
                      return _vm.validateWorkExperience(1)
                    },
                  },
                },
                [
                  _vm._v("\n      Add Another\n      "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.addSpinner,
                        expression: "addSpinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "grupa-white-btn",
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "grupa-blue-btn",
                  staticStyle: { "margin-left": "8px" },
                  on: {
                    click: function ($event) {
                      return _vm.validateWorkExperience(0)
                    },
                  },
                },
                [
                  _vm._v("\n      Save\n      "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.spinner,
                        expression: "spinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "modal-contents", attrs: { id: "main-children" } },
        [
          _c("div", { staticClass: "modal-instruction" }, [
            _vm._v(
              "\n      Add details of relevant work experiences you have. We will order the\n      list starting with the most recent\n    "
            ),
          ]),
          _c(
            "div",
            { staticClass: "edit-form" },
            [
              _c("div", { staticClass: "form-error" }, [
                _c("span", { staticClass: "error_span" }, [
                  _vm._v(_vm._s(_vm.form_errors.role)),
                ]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.role,
                    expression: "form.role",
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "Enter job title", type: "text" },
                domProps: { value: _vm.form.role },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "role", $event.target.value)
                    },
                    function ($event) {
                      _vm.form_errors.role = ""
                    },
                  ],
                },
              }),
              _c("div", { staticClass: "form-error" }, [
                _c("span", { staticClass: "error_span" }, [
                  _vm._v(_vm._s(_vm.form_errors.organization)),
                ]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.organization,
                    expression: "form.organization",
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "enter name of company", type: "text" },
                domProps: { value: _vm.form.organization },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "organization", $event.target.value)
                    },
                    function ($event) {
                      _vm.form_errors.organization = ""
                    },
                  ],
                },
              }),
              _c("div", { staticClass: "form-error" }, [
                _c("span", { staticClass: "error_span" }, [
                  _vm._v(_vm._s(_vm.form_errors.website)),
                ]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.website,
                    expression: "form.website",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  placeholder: "https://companywebsite.comy",
                  type: "text",
                },
                domProps: { value: _vm.form.website },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "website", $event.target.value)
                    },
                    function ($event) {
                      _vm.form_errors.website = ""
                    },
                  ],
                },
              }),
              _c("div", { staticClass: "form-error" }, [
                _c("span", { staticClass: "error_span" }, [
                  _vm._v(_vm._s(_vm.form_errors.company_location)),
                ]),
              ]),
              _c("vue-google-autocomplete", {
                ref: "autocomplete",
                attrs: {
                  id: "map",
                  classname: "form-control addressTextBox",
                  placeholder: "state, country",
                  types: "",
                },
                on: {
                  placechanged: _vm.getAddressData,
                  input: function ($event) {
                    _vm.form_errors.company_location = ""
                  },
                },
              }),
              _c("div", { staticClass: "form-error" }, [
                _c("span", { staticClass: "error_span" }, [
                  _vm._v(_vm._s(_vm.form_errors.start_date)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "start-date-wrapper" },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "element",
                      attrs: {
                        "close-on-content-click": false,
                        "return-value": _vm.form.start_date,
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          return _vm.$set(_vm.form, "start_date", $event)
                        },
                        "update:return-value": function ($event) {
                          return _vm.$set(_vm.form, "start_date", $event)
                        },
                      },
                      model: {
                        value: _vm.form.startMenu,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startMenu", $$v)
                        },
                        expression: "form.startMenu",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "input_wrap",
                          attrs: { slot: "activator" },
                          slot: "activator",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.start_date,
                                expression: "form.start_date",
                              },
                            ],
                            staticClass: "date_textbox",
                            attrs: {
                              type: "text",
                              placeholder: "Enter start date",
                            },
                            domProps: { value: _vm.form.start_date },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "start_date",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.form_errors.start_date = ""
                                },
                              ],
                            },
                          }),
                          _c("img", {
                            attrs: { src: "/img/onboarding/calendar.svg" },
                          }),
                        ]
                      ),
                      _c(
                        "v-date-picker",
                        {
                          attrs: {
                            "no-title": "",
                            scrollable: "",
                            max: _vm.todayDate,
                            type: "date",
                          },
                          model: {
                            value: _vm.form.start_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "start_date", $$v)
                            },
                            expression: "form.start_date",
                          },
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.form.startMenu = false
                                },
                              },
                            },
                            [_vm._v("\n              Cancel\n            ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.element.save(
                                    _vm.form.start_date
                                  )
                                },
                              },
                            },
                            [_vm._v("\n              Ok\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "form-error" }, [
                _c("span", { staticClass: "error_span" }, [
                  _vm._v(_vm._s(_vm.form_errors.end_date)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "start-date-wrapper" },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "end",
                      attrs: {
                        "close-on-content-click": false,
                        "return-value": _vm.form.end_date,
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          return _vm.$set(_vm.form, "end_date", $event)
                        },
                        "update:return-value": function ($event) {
                          return _vm.$set(_vm.form, "end_date", $event)
                        },
                      },
                      model: {
                        value: _vm.form.endMenu,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endMenu", $$v)
                        },
                        expression: "form.endMenu",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "input_wrap",
                          style: _vm.form.current_job
                            ? "background: #CDCDCD;"
                            : null,
                          attrs: { slot: "activator" },
                          slot: "activator",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.end_date,
                                expression: "form.end_date",
                              },
                            ],
                            staticClass: "date_textbox",
                            attrs: {
                              type: "text",
                              placeholder: "Enter end date",
                              disabled: _vm.form.current_job,
                            },
                            domProps: { value: _vm.form.end_date },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "end_date",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.form_errors.end_date = ""
                                },
                              ],
                            },
                          }),
                          _c("img", {
                            attrs: { src: "/img/onboarding/calendar.svg" },
                          }),
                        ]
                      ),
                      !_vm.form.current_job
                        ? _c(
                            "v-date-picker",
                            {
                              attrs: {
                                max: _vm.todayDate,
                                "no-title": "",
                                scrollable: "",
                              },
                              model: {
                                value: _vm.form.end_date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "end_date", $$v)
                                },
                                expression: "form.end_date",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.form.endMenu = false
                                    },
                                  },
                                },
                                [_vm._v("\n              Cancel\n            ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.end.save(
                                        _vm.form.end_date
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n              Ok\n            ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "left",
                    cursor: "pointer",
                    "margin-top": "16px",
                  },
                  on: {
                    click: function ($event) {
                      _vm.form.current_job = !_vm.form.current_job
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "margin-right": "8px" } }, [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.current_job,
                          expression: "form.current_job",
                        },
                      ],
                      attrs: { src: "/img/onboarding/check.svg" },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.form.current_job,
                          expression: "!form.current_job",
                        },
                      ],
                      attrs: { src: "/img/onboarding/uncheck.svg" },
                    }),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#15171a",
                        "font-weight": "normal",
                        "font-size": "14px",
                      },
                    },
                    [_vm._v("I currently work here")]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }