<template>
  <DialogComponent
    dialogTitle="Edit Personal Info"
    v-bind:closeDialog="closeDialog"
  >
    <div id="main-children" class="modal-contents">
      <div class="modal-instruction">
        Edit your full name, phone number, location and personal bio. You cannot
        change your email address at this time.
      </div>
      <div class="edit-form">
        <div class="form-error">
          <span class="error_span" v-if="form_errors.name">{{
            form_errors.name[0]
          }}</span>
        </div>
        <input
          class="form-control"
          v-model="form.name"
          placeholder="Full name"
          type="text"
        />
        <div class="form-error"></div>
        <input
          class="form-control"
          v-model="form.email"
          placeholder="Email Address"
          type="email"
          disabled
        />
        <div class="form-error">
          <span class="error_span" v-if="form_errors.phone_number">{{
            form_errors.phone_number[0]
          }}</span>
        </div>
        <div class="selectPhoneNumber">
          <vue-tel-input
            v-model="form.phone_number"
            @country-changed="countryChange"
            @validate="validationMethod"
            @input="validatePhoneNumber"
            v-bind="bindProps"
            placeholder="enter mobile phone number"
          ></vue-tel-input>
        </div>
        <div class="form-error">
          <span class="error_span" v-if="form_errors.bio">{{
            form_errors.bio[0]
          }}</span>
        </div>
        <textarea
          class="form-control"
          v-model="form.bio"
          name="overview"
          rows="4"
          id="textarea"
          style="height: unset"
          placeholder="Write a short note about yourself"
        ></textarea>
      </div>
    </div>
    <template v-slot:dialog-footer>
      <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
      <button
        style="margin-left: 8px"
        @click="handleUpdateData"
        class="grupa-blue-btn"
      >
        Update
        <img
          src="/img/lightbox/preloader.gif"
          style="margin-left: 20px"
          class="button-spinner"
          v-show="spinner"
        />
      </button>
    </template>
  </DialogComponent>
</template>

<script>
import DialogComponent from "@/components/Modals/dialogComponent";
import { VueTelInput } from "vue-tel-input";
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    form_errors: {
      type: Object,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
    handleUpdateData: {
      type: Function,
      required: true,
    },
    countryChange: {
      type: Function,
      required: true,
    },
  },
  components: {
    DialogComponent,
    VueTelInput,
  },

  data: () => ({
    phoneNoValid: "",
    phoneNumber: "",
    bindProps: {
      enabledCountryCode: true,
      validCharactersOnly: true,
    },
  }),

  created() {
   // console.log(this.form);
    this.phoneNumber = this.form.phone_number_code + this.form.phone_number;
    // alert(this.phoneNumber)
  },
  methods: {
    validationMethod: function ({ number, isValid, country }) {
      this.phoneNoValid = isValid;
    },
    validatePhoneNumber: function (string, argObj) {
      let { isValid } = argObj;

      let textBox = document.querySelector(".vue-tel-input");
      let { national } = argObj.number;
      //console.log(national);
      this.form.phone_number = national;
      if (isValid) {
        textBox.style.borderColor = "green";
      } else {
        textBox.style.borderColor = "red";
      }
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.modal-contents {
  width: 356px;
  text-align: left;
}
.modal-instruction {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.edit-form {
  margin-top: 8px;
}
.form-control {
  margin-bottom: 0;
  margin-top: 1px;
}
.form-error {
  padding-top: 4px;
  color: red;
  font-size: 12px;
  height: 18px;
}
</style>
<style>
.selectPhoneNumber .vue-tel-input {
  height: 50px;
  /* margin-bottom: 28px; */
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
}
.selectPhoneNumber .vti__dropdown {
  background: #f7f7f8;
  /* border: 1px solid #E4E5E7; */
  box-sizing: border-box;
  border-radius: 4px 0px 0px 4px;
}
</style>
