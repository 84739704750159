var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-experience-card" },
    [
      _c("div", { staticClass: "card-head" }, [
        _c("div", { staticClass: "card-title" }, [_vm._v("WORK EXPERIENCE")]),
        _c(
          "div",
          {
            staticClass: "card-action",
            on: {
              click: function ($event) {
                _vm.isDialog = true
              },
            },
          },
          [_vm._v("Add Experience")]
        ),
      ]),
      _c(
        "VuePerfectScrollbar",
        {
          staticClass: "perfect-scroll-area",
          attrs: { settings: _vm.settings },
        },
        [
          _c(
            "div",
            { staticClass: "work-experience-list" },
            _vm._l(_vm.workExperience, function (work, index) {
              return _vm.workExperience
                ? _c(
                    "div",
                    { key: index, staticClass: "work-history-container" },
                    [
                      _c("div", { staticClass: "box-wrapper" }, [
                        _c("div", [
                          _c("div", { staticClass: "job-role" }, [
                            _vm._v(_vm._s(work.role)),
                          ]),
                          _c("div", { staticClass: "company-name" }, [
                            _vm._v(_vm._s(work.organization)),
                          ]),
                        ]),
                        _c("div", { staticClass: "company-logo" }),
                      ]),
                      work.current_job
                        ? _c(
                            "div",
                            {
                              staticClass: "work-duration",
                              staticStyle: {
                                "margin-top": "4px",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm
                                      .$moment(work.start_date)
                                      .format("MMM Do YYYY")
                                  ) +
                                  "\n          - till date\n          " +
                                  _vm._s(
                                    _vm
                                      .$moment(work.start_date, "YYYYMMDD")
                                      .fromNow()
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      !work.current_job && work.end_date
                        ? _c(
                            "div",
                            {
                              staticClass: "work-duration",
                              staticStyle: {
                                "margin-top": "4px",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm
                                      .$moment(work.start_date)
                                      .format("MMM Do YYYY")
                                  ) +
                                  "\n          - " +
                                  _vm._s(
                                    _vm
                                      .$moment(work.end_date)
                                      .format("MMM Do YYYY")
                                  ) +
                                  "\n          "
                              ),
                              work.end_date && work.start_date
                                ? _c("span", [
                                    _vm._v(
                                      "\n            .\n            " +
                                        _vm._s(
                                          _vm.dateDifference(
                                            work.end_date,
                                            work.start_date
                                          )
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "box-wrapper" }, [
                        _c("div", { staticClass: "company-location" }, [
                          _vm._v(_vm._s(work.company_location)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "remove-btn",
                            on: {
                              click: function ($event) {
                                return _vm.removeWorkExperience(
                                  work.id,
                                  work.organization
                                )
                              },
                            },
                          },
                          [_vm._v("\n            Remove\n          ")]
                        ),
                      ]),
                      index < _vm.workExperience.length - 1
                        ? _c("div", { staticClass: "work-divider" })
                        : _vm._e(),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
        ]
      ),
      _vm.isDialog
        ? _c(
            "AddWorkExperienceModal",
            _vm._b(
              {},
              "AddWorkExperienceModal",
              {
                form: _vm.form,
                form_errors: _vm.form_errors,
                closeDialog: _vm.closeDialog,
                spinner: _vm.spinner,
                validateWorkExperience: _vm.validateWorkExperience,
                addSpinner: _vm.addSpinner,
                getAddressData: _vm.getAddressData,
                setUserLocation: _vm.setUserLocation,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }