<template>
  <div class="work-experience-card">
    <div class="card-head">
      <div class="card-title">WORK EXPERIENCE</div>
      <div class="card-action" @click="isDialog = true">Add Experience</div>
    </div>
    <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
      <div class="work-experience-list">
        <div
          v-if="workExperience"
          class="work-history-container"
          v-for="(work, index) in workExperience"
          :key="index"
        >
          <div class="box-wrapper">
            <div>
              <div class="job-role">{{ work.role }}</div>
              <div class="company-name">{{ work.organization }}</div>
            </div>
            <div class="company-logo">
              <!-- <img src="/img/onboarding/ms-logo.png" alt="" /> -->
            </div>
          </div>
          <div
            v-if="work.current_job"
            class="work-duration"
            style="margin-top: 4px; text-align: left"
          >
            {{ $moment(work.start_date).format("MMM Do YYYY") }}
            - till date
            {{ $moment(work.start_date, "YYYYMMDD").fromNow() }}
          </div>
          <div
            v-if="!work.current_job && work.end_date"
            class="work-duration"
            style="margin-top: 4px; text-align: left"
          >
            {{ $moment(work.start_date).format("MMM Do YYYY") }}
            - {{ $moment(work.end_date).format("MMM Do YYYY") }}
            <span v-if="work.end_date && work.start_date">
              .
              {{ dateDifference(work.end_date, work.start_date) }}
            </span>
          </div>
          <div class="box-wrapper">
            <div class="company-location">{{ work.company_location }}</div>
            <!--   v-if="!HomeSpinner" -->
            <div
              class="remove-btn"
              @click="removeWorkExperience(work.id, work.organization)"
            >
              Remove
            </div>
           
          </div>
          <div
            v-if="index < workExperience.length - 1"
            class="work-divider"
          ></div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <AddWorkExperienceModal
      v-if="isDialog"
      v-bind="{
        form,
        form_errors,
        closeDialog,
        spinner,
        validateWorkExperience,
        addSpinner,
        getAddressData,
        setUserLocation,
      }"
    />
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapState } from "vuex";
import { devWorkExperience } from "@/api";
import AddWorkExperienceModal from "./modals/workExperienceModal";
import appMixin from "@/mixins/appMixin";

export default {
  mixins: [appMixin],
  props: {
    workExperience: {
      type: Array,
      required: true,
    },
    removeWorkExperience: {
      type: Function,
      required: true,
    },
    updateWorkExperience: {
      type: Function,
      required: true,
    },
    HomeSpinner: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AddWorkExperienceModal,
    VuePerfectScrollbar,
  },
  data: () => ({
    isDialog: false,
    form: {
      role: "",
      organization: "",
      website: "",
      company_location: "",
      start_date: "",
      end_date: "",
      current_job: false,
      startMenu: false,
      endMenu: false,
    },
    form_errors: {
      role: "",
      organization: "",
      website: "",
      company_location: "",
      start_date: "",
      end_date: "",
    },
    spinner: false,
    addSpinner: false,
    response_err: "",
    settings: {
      maxScrollbarLength: 60,
    },
  }),
  created() {},
  watch: {
    "form.current_job": {
      immediate: false,
      handler: function (val) {
        if (val == true) {
          this.form.end_date = "";
        }
      },
    },
    response_err: {
      handler: function (val) {
        if (val != "") {
          setTimeout(() => {
            this.response_err = "";
          }, 5000);
        }
      },
    },
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    closeDialog() {
      this.isDialog = false;
    },
    validateWorkExperience(addMore) {
      if (this.form.role == "") {
        this.form_errors.role = "Job title is required";
      } else if (this.form.organization == "") {
        this.form_errors.organization = "Company name is required";
      } else if (this.form.website == "") {
        this.form_errors.website = "Company website is required";
      } else if (this.form.company_location == "") {
        this.form_errors.company_location = "Company location is required";
      } else if (this.form.start_date == "") {
        this.form_errors.start_date = "Start date is required";
      } else if (this.form.end_date == "" && !this.form.current_job) {
        this.form_errors.end_date = "End date is required";
      } else {
        let workArray = [];
        workArray.push(this.form);
        let work_experiece = { work_experience: workArray };
        this.handleAddWorkExperience(work_experiece, addMore);
        this.$developConsole(this.form, "My Form.....");
      }
    },
    handleAddWorkExperience(data, addMore) {
      addMore == 1 ? (this.addSpinner = true) : (this.spinner = true);
      devWorkExperience(data)
        .then((response) => {
          if (response.data.status == 1) {
            this.spinner = false;
            this.addSpinner = false;
            this.updateWorkExperience(response.data.work_experiences);
            this.setSuccessMsg("Work Experience added successfully");
            if (addMore !== 1) {
              this.isDialog = false;
            }
          }
        })
        .catch((err) => {
          this.spinner = false;
          this.addSpinner = false;
          if (err.response.data.status == 0) {
            this.response_err = err.response.data.msg;

            this.$developConsole(err.response);
          } else {
            this.setErrorMsg("An error occurred please try again");
            this.$developConsole(err.response);
          }
        });
    },
    getAddressData: function (addressData, placeResultData, id) {
      const { formatted_address } = placeResultData;
      let address;
      formatted_address
        ? (address = formatted_address)
        : (address = document.querySelector(".addressTextBox").value);
      this.form.company_location = address;
    },
    setUserLocation() {
      let { city, country_name } = this.userLocation;
      if (country_name) {
        let currentLocation = `${city}, ${country_name}`;
        document.querySelector(".addressTextBox").value = currentLocation;
        this.form.company_location = currentLocation;
      }
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser", "userLocation"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.work-experience-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px 12px;
  width: 50%;
  min-height: 350px;
}
.card-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0px 12px;
}
.card-title {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
}
.card-action {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  cursor: pointer;
}
.work-history-container {
  margin-top: 28px;
}
.work-experience-list .work-history-container:nth-of-type(1) {
  margin-top: 12px;
}
.work-experience-list {
  height: 330px;
  padding: 0px 12px;
}
.box-wrapper {
  display: flex;
  justify-content: space-between;
}
.company-name {
  margin-top: 2px;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: initial;
}
.job-role {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.company-logo {
}
.remove-btn {
  cursor: pointer;
  line-height: 100%;
}
.work-duration,
.company-location,
.remove-btn {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.work-divider {
  border-top: 1px solid #e4e5e7;
  margin-top: 24px;
}
</style>
