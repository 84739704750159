<template>
  <div>
    <div class="deactivate-account-container">
      <div class="deactivate-card">
        <button @click="isModal = true" class="grupa-red-btn">
          Deactivate My Account
        </button>
      </div>
    </div>
    <DeactivateModal
      v-if="isModal"
      v-bind="{ spinner, closeDialog }"
      v-bind:handleDelete="deactivateUserAccount"
    />
  </div>
</template>

<script>
import { deactivateAccount } from "@/api";
import DeactivateModal from "./modals/deactivateModal.vue";
import { deleteAccount } from "@/utils/logoutUser";

export default {
  props: {},
  components: {
    DeactivateModal,
  },

  data: () => ({
    isModal: false,
    spinner: false,
  }),

  created() {},
  methods: {
    closeDialog() {
      this.isModal = false;
    },
    deactivateUserAccount() {
      this.spinner = true;
      deactivateAccount()
        .then((response) => {
          if (response.data.status == 1) {
            deleteAccount();
             localStorage.removeItem("vcs");
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.spinner = true;
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.deactivate-account-container {
  display: flex;
  margin-top: 12px;
}
.deactivate-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px 12px;
  width: 49%;
}
</style>
