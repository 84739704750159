<template>
  <div>
    <Toolbar stageTitle="Profile">
      <div style="margin-top: 20px">
        <!-- <img src="/img/dashboard/notification-icon.svg" alt="" /> -->
      </div>
    </Toolbar>
    <Loader v-if="teamData == null" />
    <div v-else class="page-container">
      <!-- <LinkedAccount v-bind="{ workExperience }" /> -->
      <ProfileDetails />
      <div class="other-details">
        <UserWorkExperience
          v-bind="{
            workExperience,
            updateWorkExperience,
            removeWorkExperience,
            HomeSpinner,
          }"
        />
        <UserEducationHistory v-bind="{ devEducationList }" />
      </div>
      <DeactivateAccount />
      <confirmModal
        v-if="isWorkRemoveModal"
        :dialogeTitle="`Delete Work Experience`"
        :dialogeYesLabel="`Yes Delete`"
        :dialogeNoLabel="`Cancel`"
        :dialogeBodyLabel="`You are about to delete your work experience with ${isWorkOrganisation}, this can not be undone`"
        :closeDialog="closeDialog"
        :spinner="HomeSpinner"
        :handleDelete="DeleteExperienceYesButton"
      />
    </div>
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>

<script>
import {
  getDev,
  getDevWorkExperience,
  getDevEducation,
  removeWorkExperienceAPI,
} from "@/api";
import { mapState, mapActions } from "vuex";
import Toolbar from "@/components/toolbar/generalToolBar";
import LinkedAccount from "./linkedinAccount";
import ProfileDetails from "./profileDetails";
import UserWorkExperience from "./userWorkExperience";
import UserEducationHistory from "./userEducation";
import AlertSuccess from "@/components/alertSuccess";
import DeactivateAccount from "./deactivateAccount.vue";
import ConfirmModal from "./modals/confirmModal.vue";
import AlertError from "@/components/alertError";
import Loader from "@/components/general/centerLoader";
export default {
  props: {},
  components: {
    Toolbar,
    LinkedAccount,
    ProfileDetails,
    UserWorkExperience,
    UserEducationHistory,
    AlertSuccess,
    AlertError,
    Loader,
    ConfirmModal,
    DeactivateAccount,
  },

  data: () => ({
    teamData: null,
    workExperience: [],
    devEducationList: [],
    HomeSpinner: false,
    isWorkRemoveModal: false,
    isWorkOrganisation: "",
    isWorkId: "",
  }),
  created() {
    this.getTeamDetails();
    this.getWorkExperience();
    this.getEducationList();
  },
  methods: {
    ...mapActions("devDashboard", ["setTeamDetails"]),
     ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    closeDialog() {
      this.isWorkRemoveModal = false;
      this.HomeSpinner = false;
    },
    async getTeamDetails() {
      await getDev()
        .then((res) => {
          if (res.data) {
            this.teamData = res.data;
            this.$developConsole(res.data, "Team data fetched...");
            this.setTeamDetails(res.data);
          }
        })
        .catch((err) => {});
    },
    async getWorkExperience() {
      const response = await getDevWorkExperience();
      this.workExperience = response.data.work_experiences;
    },
    async getEducationList() {
      await getDevEducation()
        .then((response) => {
          if (response.data.status == 1) {
            this.devEducationList = response.data.data;
          }
        })
        .catch((err) => {});
    },
    updateWorkExperience(payload) {
      this.workExperience = payload;
    },
    DeleteExperienceYesButton() {
      this.HomeSpinner = true;
      removeWorkExperienceAPI(this.isWorkId)
        .then((response) => {
          this.HomeSpinner = false;
          this.setSuccessMsg(`${this.isWorkOrganisation} - Work experience removed successfully`);
         // console.log(response.data.data);
          this.workExperience = response.data.data
           this.closeDialog()
        })
        .catch((err) => {
          this.setErrorMsg(`An error occurred please try again`);
          this.HomeSpinner = false;
        });
    },
    removeWorkExperience(id, organization) {
      this.isWorkRemoveModal = true;
      this.isWorkOrganisation = organization;
      this.isWorkId = id;

      // alert(id);
      /*  this.HomeSpinner = true;
      removeWorkExperienceAPI(id)
        .then((response) => {
          this.HomeSpinner = false;
          this.setSuccessMsg("Work experience removed successfully");
          console.log(response.data.data);
        })
        .catch((err) => {
          this.setErrorMsg(`An error occurred please try again`);
          this.HomeSpinner = false;
        }); */
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-container {
  padding: 16px 32px 32px;
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
}
.other-details {
  display: flex;
  margin-top: 16px;
}
</style>
