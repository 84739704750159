<template>
  <DialogComponent
    dialogTitle="Edit Team Role & Skills"
    v-bind:closeDialog="closeDialog"
  >
    <div id="main-children" class="modal-contents">
      <div class="modal-instruction">
        Edit your personal and technical skills. To change your designated team
        role, contact your team lead
      </div>
      <div class="edit-form">
        <input
          class="form-control"
          v-model="stackForm.team_role"
          placeholder="Full name"
          type="text"
          disabled
        />
        <div>
          <v-combobox
            class="vuetify-combo language_combo"
            @change="updateStack('languages')"
            id="languages"
            v-model="tempArray.tools"
            :items="tools_list"
            item-value="tools_list.name"
            flat
            item-text="name"
            multiple
            persistent-hint
            small-chips
            solo
            placeholder="select languages"
          ></v-combobox>
        </div>
        <div>
          <v-combobox
            class="vuetify-combo"
            @change="updateStack('framework')"
            id="framework"
            v-model="tempArray.dev_framework"
            :items="framework"
            item-value="framework.name"
            item-text="name"
            flat
            multiple
            persistent-hint
            small-chips
            solo
            placeholder="select tools"
          ></v-combobox>
        </div>
        <div>
          <v-combobox
            class="vuetify-combo"
            @change="updateStack('database')"
            id="database"
            v-model="tempArray.dev_database"
            :items="db_tools"
            item-value="db_tools.name"
            flat
            item-text="name"
            hint="seperate each item with tab key"
            label="Tools and languages"
            multiple
            persistent-hint
            small-chips
            solo
            placeholder="select tools"
          ></v-combobox>
        </div>
      </div>
    </div>
    <template v-slot:dialog-footer>
      <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
      <button
        @click="handleUpdateFramework"
        style="margin-left: 8px"
        class="grupa-blue-btn"
      >
        Update
        <img
          src="/img/lightbox/preloader.gif"
          style="margin-left: 20px"
          class="button-spinner"
          v-show="spinner"
        />
      </button>
    </template>
  </DialogComponent>
</template>

<script>
import DialogComponent from "@/components/Modals/dialogComponent";
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    stackForm: {
      type: Object,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
    tools_list: {
      type: Array,
      required: true,
    },
    updateStack: {
      type: Function,
      required: true,
    },
    tempArray: {
      type: Object,
      required: true,
    },
    framework: {
      type: Array,
      required: true,
    },
    db_tools: {
      type: Array,
      required: true,
    },
    design_tools: {
      type: Array,
      required: true,
    },
    handleUpdateFramework: {
      type: Function,
      required: true,
    },
  },
  components: {
    DialogComponent,
  },

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.modal-contents {
  width: 356px;
  text-align: left;
}
.modal-instruction {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.edit-form {
  margin-top: 8px;
}
.vuetify-combo,
.form-control {
  margin-bottom: 0;
  margin-top: 18px;
}
</style>
<style>
.language_combo.v-text-field > .v-input__control > .v-input__slot:before {
  border: 0px;
}
.vuetify-combo .v-input__slot {
  border: solid 1px #ced4da;
  margin-bottom: 0 !important;
}
.vuetify-combo.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
</style>

