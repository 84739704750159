var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-details" },
    [
      _c("div", { staticClass: "personal-details-wrap" }, [
        _c("div", { staticClass: "personal-details" }, [
          _c("div", { staticClass: "card-head" }, [
            _c("div", { staticClass: "card-title" }, [_vm._v("FULL NAME")]),
            _c(
              "div",
              {
                staticClass: "card-action",
                on: { click: _vm.handleEditProfile },
              },
              [_vm._v("Edit")]
            ),
          ]),
          _c("div", { staticClass: "user-details-text" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.form.phoneUpdated ? _vm.form.name : _vm.developer.name
                ) +
                "\n      "
            ),
          ]),
          _c("div", { staticClass: "user-email-address" }, [
            _c("div", { staticClass: "card-title" }, [_vm._v("email address")]),
            _c("div", { staticClass: "user-details-text" }, [
              _vm._v(_vm._s(_vm.developer.email)),
            ]),
          ]),
          _c("div", { staticClass: "user-phone-number" }, [
            _c("div", { staticClass: "user-phonenumber" }, [
              _c("div", { staticClass: "card-title" }, [
                _vm._v("phone number"),
              ]),
              _c("div", { staticClass: "user-details-text" }, [
                _vm.developer.phone_number_code || _vm.form.phoneUpdated
                  ? _c("span", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.form.phoneUpdated
                              ? _vm.form.phone_number_code
                              : _vm.developer.phone_number_code
                          ) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm.developer.phone_number || _vm.form.phoneUpdated
                  ? _c("span", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.form.phoneUpdated
                              ? _vm.form.phone_number
                              : _vm.developer.phone_number
                          ) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "user-location" }, [
              _c("div", { staticClass: "card-title" }, [_vm._v("location")]),
              _c("div", { staticClass: "user-details-text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      "" +
                        (_vm.developer.user_location
                          ? _vm.developer.user_location
                          : "")
                    ) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "user-bio" }, [
            _c("div", { staticClass: "card-title" }, [_vm._v("PERSONAL BIO")]),
            _c(
              "div",
              {
                staticClass: "user-bio-text",
                style:
                  _vm.developer.bio || _vm.form.phoneUpdated
                    ? "color:#1b1e22"
                    : "",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.form.phoneUpdated
                        ? _vm.truncate(_vm.form.bio, 180)
                        : _vm.developer.bio
                        ? _vm.truncate(_vm.developer.bio, 180)
                        : "Write a short bio about yourself "
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "user-image-and-stack" }, [
        _c("div", { staticClass: "profile-image" }, [
          _c("div", { staticClass: "card-head" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v("profile picture"),
            ]),
            _c("input", {
              staticClass: "input-file",
              attrs: {
                type: "file",
                accept: "image/*",
                name: "profilePhoto",
                size: "chars",
              },
              on: { blur: _vm.handleUploadImage, change: _vm.onFileChange },
            }),
            _c(
              "div",
              {
                staticClass: "card-action",
                on: {
                  click: function ($event) {
                    return _vm.openFile()
                  },
                },
              },
              [_vm._v("Change")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "profile-picture" },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "profile_bg",
                  style: {
                    backgroundImage: "url(" + _vm.imageLink + ")",
                  },
                  attrs: { size: 204, color: "grey lighten-4" },
                },
                [
                  _vm.profile_spinner
                    ? _c("img", {
                        staticClass: "button-spinner",
                        staticStyle: { height: "20px", width: "20px" },
                        attrs: { src: "/img/lightbox/preloader.gif" },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "user-tech-stack" }, [
          _c("div", { staticClass: "card-head" }, [
            _c("div", { staticClass: "card-title" }, [_vm._v("TEAM ROLE")]),
            _c(
              "div",
              {
                staticClass: "card-action",
                on: { click: _vm.updateToolsAndFramework },
              },
              [_vm._v("Edit")]
            ),
          ]),
          _c("div", { staticClass: "user-details-text" }, [
            _vm._v(_vm._s(_vm.developer.team_role)),
          ]),
          _c("div", { staticClass: "user-stack-Container" }, [
            _c("div", { staticClass: "card-title" }, [_vm._v("skills")]),
            _c("div", { staticClass: "framework-tools" }, [
              _c(
                "div",
                { staticClass: "stacks-wrapper" },
                _vm._l(_vm.developerStacks(), function (stack, i) {
                  return _c("div", { key: i, staticClass: "stack-div" }, [
                    _vm._v(
                      "\n              " + _vm._s(stack) + "\n            "
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _vm.isDialog
        ? _c(
            "EditPersonalInfoModal",
            _vm._b(
              {},
              "EditPersonalInfoModal",
              {
                closeDialog: _vm.closeDialog,
                form: _vm.form,
                spinner: _vm.spinner,
                form_errors: _vm.form_errors,
                handleUpdateData: _vm.handleUpdateData,
                countryChange: _vm.countryChange,
              },
              false
            )
          )
        : _vm._e(),
      _vm.isRoleDialog
        ? _c(
            "EditTeamRoleModal",
            _vm._b(
              {},
              "EditTeamRoleModal",
              {
                closeDialog: _vm.closeDialog,
                spinner: _vm.spinner,
                stackForm: _vm.stackForm,
                tools_list: _vm.tools_list,
                updateStack: _vm.updateStack,
                tempArray: _vm.tempArray,
                all_tools: _vm.all_tools,
                framework: _vm.framework,
                db_tools: _vm.db_tools,
                design_tools: _vm.design_tools,
                handleUpdateFramework: _vm.handleUpdateFramework,
              },
              false
            )
          )
        : _vm._e(),
      _vm.isPasswordDialog
        ? _c(
            "ChangePasswordModal",
            _vm._b(
              {},
              "ChangePasswordModal",
              {
                closeDialog: _vm.closeDialog,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }