var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Toolbar", { attrs: { stageTitle: "Profile" } }, [
        _c("div", { staticStyle: { "margin-top": "20px" } }),
      ]),
      _vm.teamData == null
        ? _c("Loader")
        : _c(
            "div",
            { staticClass: "page-container" },
            [
              _c("ProfileDetails"),
              _c(
                "div",
                { staticClass: "other-details" },
                [
                  _c(
                    "UserWorkExperience",
                    _vm._b(
                      {},
                      "UserWorkExperience",
                      {
                        workExperience: _vm.workExperience,
                        updateWorkExperience: _vm.updateWorkExperience,
                        removeWorkExperience: _vm.removeWorkExperience,
                        HomeSpinner: _vm.HomeSpinner,
                      },
                      false
                    )
                  ),
                  _c(
                    "UserEducationHistory",
                    _vm._b(
                      {},
                      "UserEducationHistory",
                      { devEducationList: _vm.devEducationList },
                      false
                    )
                  ),
                ],
                1
              ),
              _c("DeactivateAccount"),
              _vm.isWorkRemoveModal
                ? _c("confirmModal", {
                    attrs: {
                      dialogeTitle: "Delete Work Experience",
                      dialogeYesLabel: "Yes Delete",
                      dialogeNoLabel: "Cancel",
                      dialogeBodyLabel:
                        "You are about to delete your work experience with " +
                        _vm.isWorkOrganisation +
                        ", this can not be undone",
                      closeDialog: _vm.closeDialog,
                      spinner: _vm.HomeSpinner,
                      handleDelete: _vm.DeleteExperienceYesButton,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }