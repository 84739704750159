var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "linkedin-conatiner" }, [
    _c("div", { staticClass: "contain-title" }, [_vm._v("linked accounts")]),
    !_vm.workExperience.length
      ? _c("div", { staticClass: "container-below" }, [
          _vm._m(0),
          _c("div", { staticClass: "linkedin-instruction" }, [
            _vm._v(
              "\n      Connect with linkedin to update your personal and professional profile\n    "
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "linkedin-connected" }, [
      _c(
        "div",
        { staticClass: "linkedin-profile" },
        [
          _c("v-avatar", {
            staticClass: "profile_bg",
            style: {
              backgroundImage:
                "url(" +
                (_vm.$imageUrl + _vm.currentUser.profile_picture ||
                  _vm.imageAvatar) +
                ")",
            },
            attrs: { size: 50, color: "grey lighten-4" },
          }),
          _c("div", { staticClass: "user-details" }, [
            _c("div", { staticClass: "user-name" }, [
              _vm._v(_vm._s(_vm.currentUser.name)),
            ]),
            _c("div", { staticClass: "linkedin-text" }, [_vm._v("LinkedIn")]),
          ]),
        ],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "disconnect-linkedin" }, [_vm._v("Disconnect")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "linkedin-button" }, [
      _c("img", { attrs: { src: "/img/onboarding/LinkedIn.svg" } }),
      _c("span", [_vm._v("Connect to LinkedIn")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "edit-button" }, [
      _c("img", { attrs: { src: "/img/onboarding/LinkedIn.svg" } }),
      _c("span", [_vm._v("Click to Edit Info")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }