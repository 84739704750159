<template>
  <div class="profile-details">
    <div class="personal-details-wrap">
      <div class="personal-details">
        <div class="card-head">
          <div class="card-title">FULL NAME</div>
          <div class="card-action" @click="handleEditProfile">Edit</div>
        </div>
        <div class="user-details-text">
          {{ form.phoneUpdated ? form.name : developer.name }}
        </div>
        <div class="user-email-address">
          <div class="card-title">email address</div>
          <div class="user-details-text">{{ developer.email }}</div>
        </div>
        <div class="user-phone-number">
          <div class="user-phonenumber">
            <div class="card-title">phone number</div>
            <div class="user-details-text">
              <span v-if="developer.phone_number_code || form.phoneUpdated">
                {{
                  form.phoneUpdated
                    ? form.phone_number_code
                    : developer.phone_number_code
                }}
              </span>
              <span v-if="developer.phone_number || form.phoneUpdated">
                {{
                  form.phoneUpdated ? form.phone_number : developer.phone_number
                }}
              </span>
            </div>
          </div>
          <div class="user-location">
            <div class="card-title">location</div>
            <div class="user-details-text">
              {{ `${developer.user_location ? developer.user_location : ""}` }}
            </div>
          </div>
        </div>
        <div class="user-bio">
          <div class="card-title">PERSONAL BIO</div>
          <div
            class="user-bio-text"
            :style="developer.bio || form.phoneUpdated ? 'color:#1b1e22' : ''"
          >
            {{
              form.phoneUpdated
                ? truncate(form.bio, 180)
                : developer.bio
                ? truncate(developer.bio, 180)
                : "Write a short bio about yourself "
            }}
          </div>
        </div>
      </div>
      <!-- <div class="user-password">
        <div class="card-head">
          <div class="card-title">password</div>
          <div class="card-action" @click="isPasswordDialog = true">Change</div>
        </div>
        <div class="password-text">********</div>
      </div> -->
    </div>
    <div class="user-image-and-stack">
      <div class="profile-image">
        <div class="card-head">
          <div class="card-title">profile picture</div>
          <input
            type="file"
            accept="image/*"
            @blur="handleUploadImage"
            @change="onFileChange"
            name="profilePhoto"
            size="chars"
            class="input-file"
          />
          <div class="card-action" @click="openFile()">Change</div>
        </div>
        <div class="profile-picture">
          <v-avatar
            :size="204"
            color="grey lighten-4"
            class="profile_bg"
            :style="{
              backgroundImage: `url(${imageLink})`,
            }"
          >
            <img
              src="/img/lightbox/preloader.gif"
              style="height: 20px; width: 20px"
              class="button-spinner"
              v-if="profile_spinner"
            />
          </v-avatar>
        </div>
      </div>
      <div class="user-tech-stack">
        <div class="card-head">
          <div class="card-title">TEAM ROLE</div>
          <div class="card-action" @click="updateToolsAndFramework">Edit</div>
        </div>
        <div class="user-details-text">{{ developer.team_role }}</div>
        <div class="user-stack-Container">
          <div class="card-title">skills</div>
          <div class="framework-tools">
            <div class="stacks-wrapper">
              <div
                v-for="(stack, i) in developerStacks()"
                :key="i"
                class="stack-div"
              >
                {{ stack }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditPersonalInfoModal
      v-if="isDialog"
      v-bind="{
        closeDialog,
        form,
        spinner,
        form_errors,
        handleUpdateData,
        countryChange,
      }"
    />
    <EditTeamRoleModal
      v-if="isRoleDialog"
      v-bind="{
        closeDialog,
        spinner,
        stackForm,
        tools_list,
        updateStack,
        tempArray,
        all_tools,
        framework,
        db_tools,
        design_tools,
        handleUpdateFramework,
      }"
    />
    <ChangePasswordModal
      v-if="isPasswordDialog"
      v-bind="{
        closeDialog,
      }"
    />
  </div>
</template>

<script>
import EditPersonalInfoModal from "./modals/personalInfoModal";
import EditTeamRoleModal from "./modals/editTeamRoleModal";
import ChangePasswordModal from "./modals/changePasswordModal";
import { editDevImage } from "@/api";
import truncate from "@/mixins/truncate";
import {
  getToolsAndFrameWorks,
  updateDevInfo,
  devUpdateToolsAndFramework,
} from "@/api/team";
import { mapState, mapActions } from "vuex";
export default {
  mixins: [truncate],
  props: {},
  components: {
    EditPersonalInfoModal,
    EditTeamRoleModal,
    ChangePasswordModal,
  },

  data: () => ({
    imageAvatar: "/img/onboarding/profile-avatar.svg",
    stacks: ["JavaScript", "NodeJs", "Laravel", "VueJs"],
    isDialog: false,
    isRoleDialog: false,
    isPasswordDialog: false,
    form_errors: {
      name: "",
      phone_number: "",
      bio: "",
    },
    form: {
      name: "",
      email: "",
      phone_number: "",
      bio: "",
      phone_number_code: "",
      phoneUpdated: false,
    },
    stackForm: {
      team_role: "",
      language_tools: "",
      framework_tools: "",
      database_tools: "",
      design_tools: "",
    },
    spinner: false,
    tempArray: {
      tools: [],
      dev_framework: [],
      dev_database: [],
      design: [],
    },
    tools_list: [],
    all_tools: [],
    specializations: [],
    framework: [],
    design_tools: [],
    db_tools: [],
    passwordForm: {
      current_password: "",
      new_password: "",
      repeat_password: "",
    },
    imageLink: "",
    image_name: "",
    profile_spinner: false,
  }),

  created() {
    this.getTool();
    this.setUserImage();
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", ["updateDeveloperInfo"]),
    updateStack(id) {
      var t = [];
      let toolStack = this.toolCategory(id);
      toolStack.array.forEach((element) => {
        if (typeof element == "object") {
          t.push(element.name);
        } else if (typeof element == "string") {
          t.push(element);
        }
      });
      this.stackForm[toolStack.form] = t;
      let el = document.getElementById(id);
      var e = new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        char: "Escape",
        key: "Escape",
        shiftKey: true,
        keyCode: 27,
      });
      el.dispatchEvent(e);
    },
    closeDialog() {
      this.isDialog = false;
      this.isRoleDialog = false;
      this.isPasswordDialog = false;
    },
    setUserImage() {
      this.imageLink =
        this.$imageUrl + this.developer.profile_picture || this.imageAvatar;
    },
    getTool() {
      getToolsAndFrameWorks().then((response) => {
        if (response.data.status == 1) {
          this.all_tools = response.data.data;
          this.tools_list = this.all_tools.filter(
            (data) => data._type == "language"
          );
          this.framework = this.all_tools.filter(
            (data) => data._type == "framework/library"
          );
          this.design_tools = this.all_tools.filter(
            (data) => data._type == "design"
          );
          this.db_tools = this.all_tools.filter(
            (data) => data._type == "database"
          );
        }
      });
    },
    handleEditProfile() {
      let { name, email, bio, phone_number, phone_number_code } = JSON.parse(
        JSON.stringify(this.developer)
      );

      this.form = {
        ...this.form,
        name,
        email,
        bio,
        phone_number,
        phone_number_code,
      };
      console.log(" --- ", this.form);
      this.isDialog = true;
    },
    updateToolsAndFramework() {
      let {
        language_tools: tools,
        database_tools: dev_database,
        framework_tools: dev_framework,
        design_tools: design,
        team_role,
      } = JSON.parse(JSON.stringify(this.developer));
      this.tempArray = {
        tools,
        dev_database,
        dev_framework,
        design,
        team_role,
      };
      this.stackForm = {
        language_tools: tools,
        database_tools: dev_database,
        framework_tools: dev_framework,
        design_tools: design,
        team_role,
      };
      this.isRoleDialog = true;
    },
    handleUpdateData() {
      this.form_errors = {
        name: [],
        phone_number: [],
        bio: [],
      };
      if (!this.form.name) {
        this.form_errors.name[0] = "Please fill in your name";
      }
      if (!this.form.phone_number) {
        this.form_errors.phone_number[0] = "Phone number is required";
      }
      if (!this.form.bio) {
        this.form_errors.bio[0] = "This field is required";
      }
      if (
        this.form_errors.name.length == 0 &&
        this.form_errors.phone_number.length == 0 &&
        this.form_errors.bio.length == 0
      ) {
        this.spinner = true;
        let payload = this.form;
        console.log({ payload });
        updateDevInfo(payload)
          .then((resp) => {
            this.spinner = false;
            if (resp.data.status == 1) {
              // console.log(resp.data.data)
              this.form.phoneUpdated = true;
              // this.updateDeveloperInfo(resp.data.data);
              this.setSuccessMsg("Record Updated");
              this.isDialog = false;
              //location.reload();
            }
          })
          .catch((err) => {
            this.isDialog = false;
            this.spinner = false;
            this.setErrorMsg("Please try again!!");
          });
      }
    },
    openFile() {
      document.querySelector(".input-file").click();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.image_name = e.target.files[0];
      this.imageLink = URL.createObjectURL(file);
      this.handleUploadImage();
    },
    handleUploadImage() {
      let dataForm = new FormData();
      dataForm.append("profile_picture", this.image_name);
      this.profile_spinner = true;
      editDevImage(dataForm)
        .then((response) => {
          if (response.data.status == 1) {
            this.profile_spinner = false;
            // this.updateDeveloperInfo(response.data["0"]);
            this.setSuccessMsg("Profile Image Updated");
            //console.log('Profile picture updated')
          } else if (response.data.status == 0) {
            this.profile_spinner = false;
            this.setErrorMsg(response.data.msg);
          }
        })
        .catch((error) => {
          this.profile_spinner = false;
          this.setErrorMsg("Network Error, Please Try Again...");
        });
    },
    toolCategory(id) {
      switch (id) {
        case "languages":
          return {
            array: this.tempArray.tools,
            form: "language_tools",
          };
        case "framework":
          return {
            array: this.tempArray.dev_framework,
            form: "framework_tools",
          };
        case "database":
          return {
            array: this.tempArray.dev_database,
            form: "database_tools",
          };
        case "design":
          return {
            array: this.tempArray.design,
            form: "design_tools",
          };
      }
    },
    handleUpdateFramework() {
      this.spinner = true;
      let payload = this.stackForm;
      devUpdateToolsAndFramework(payload)
        .then((response) => {
          //alert("done");
          this.spinner = false;
          if (response.data.status == 1) {
            this.updateDeveloperInfo(response.data.data);

            this.setSuccessMsg("Tools Updated Successfully");
            this.isRoleDialog = false;
          } else if (response.data.status == 0) {
            this.setErrorMsg(response.data.msg);
          }
        })
        .catch((error) => {
          this.$developConsole("An error ......");
        });
    },
    developerStacks() {
      let frameWork = [];
      let { language_tools, database_tools, framework_tools, design_tools } =
        this.developer;
      let tools = [
        language_tools,
        database_tools,
        framework_tools,
        design_tools,
      ];

      if (typeof language_tools != "object") {
        // console.log(tools);
        // console.log(JSON.parse(tools.replace(/\\/g, "")));
      }
      // console.log("lang:: ", language_tools);
      //console.log("typeof:: ", typeof language_tools);
      tools.map((tool) => {
        if (tool && tool !== null) {
          frameWork = [...frameWork, ...tool];
        }
      });
      // console.log({frameWork})

      if (typeof language_tools === "object") {
        // alert("object");
      } else {
        //alert("string");
        // console.log((`${frameWork}`))
      }
      return frameWork;
    },
    countryChange(country) {
      console.log({ country });
      this.form.phone_number_code = `+${country.dialCode}`;
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    developer() {
      // console.log(this.teamInfo.user)
      return this.teamInfo.user;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.profile-details {
  display: flex;
  margin-top: 16px;
}
.user-image-and-stack,
.personal-details-wrap {
  width: 50%;
  text-align: left;
}
.user-image-and-stack {
  margin-left: 16px;
}
.user-tech-stack,
.profile-image,
.user-password,
.personal-details {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
}
.user-tech-stack,
.user-password {
  margin-top: 16px;
}
.card-head {
  display: flex;
  justify-content: space-between;
}
.card-title {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
}
.card-action {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  cursor: pointer;
}
.user-details-text {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
}
.user-stack-Container,
.user-bio,
.user-phone-number,
.user-email-address {
  border-top: 1px solid #e4e5e7;
  padding-top: 24px;
  margin-top: 24px;
}
.user-phone-number {
  padding-top: 8px;
}
.user-phone-number .card-title {
  padding-top: 16px;
}
.user-phone-number {
  display: flex;
}
.user-phonenumber {
  width: 50%;
  padding-bottom: 16px;
}
.user-location {
  padding-left: 24px;
  border-left: 1px solid #e4e5e7;
  padding-bottom: 16px;
}
.user-bio {
  margin-top: 8px;
}
.user-bio-text {
  font-size: 14px;
  line-height: 130%;
  color: #c8cbd0;
  margin-top: 24px;
  height: 180px;
}
.password-text {
  margin-top: 24px;
  margin-bottom: 48px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.profile-image {
  padding-bottom: 48px;
}
.profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
.profile_bg {
  border: solid 2px #f7f7f8;
}
.framework-tools {
  margin-top: 24px;
  height: 62px;
}
.stacks-wrapper {
  flex-wrap: wrap;
  display: flex;
}
.stack-div {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin: 0px 8px 8px 0px;
  height: 25px;
}
.input-file {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}
.deactivate-account {
  margin-top: 24px;
}
</style>
