<template>
  <div class="linkedin-conatiner">
    <div class="contain-title">linked accounts</div>
    <div v-if="!workExperience.length" class="container-below">
      <button class="linkedin-button">
        <img src="/img/onboarding/LinkedIn.svg" />
        <span>Connect to LinkedIn</span>
      </button>
      <div class="linkedin-instruction">
        Connect with linkedin to update your personal and professional profile
      </div>
    </div>
    <div class="linkedin-connected">
      <div class="linkedin-profile">
        <v-avatar
          :size="50"
          color="grey lighten-4"
          class="profile_bg"
          :style="{
            backgroundImage: `url(${
              $imageUrl + currentUser.profile_picture || imageAvatar
            })`,
          }"
        >
        </v-avatar>
        <div class="user-details">
          <div class="user-name">{{ currentUser.name }}</div>
          <div class="linkedin-text">LinkedIn</div>
        </div>
      </div>
      <div class="edit-button">
        <img src="/img/onboarding/LinkedIn.svg" />
        <span>Click to Edit Info</span>
      </div>
      <div class="disconnect-linkedin">Disconnect</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    workExperience: {
      type: Array,
      required: true,
    },
  },
  components: {},

  data: () => ({
    imageAvatar: "/img/onboarding/profile-avatar.svg",
  }),

  created() {},
  methods: {},
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    currentUser() {
      return this.teamInfo.user;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.linkedin-conatiner {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
}
.contain-title {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
  text-align: left;
}
.container-below {
  display: flex;
  margin-top: 24px;
}
.linkedin-button {
  background: #0288d1;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fdfdfd;
  padding: 9px;
  width: 410px;
}
.linkedin-button span {
  text-align: center;
  margin-left: 80px;
}
.linkedin-instruction {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 34px;
  margin-top: 7px;
  width: 410px;
  text-align: left;
}
.linkedin-connected {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.linkedin-profile {
  display: flex;
}
.user-details {
  margin-left: 12px;
  text-align: left;
}
.user-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.linkedin-text {
  font-size: 12px;
  line-height: 140%;
  color: #0288d1;
  margin-top: 2px;
}
.disconnect-linkedin {
  font-size: 14px;
  line-height: 130%;
  text-align: right;
  color: #53585f;
}
.edit-button {
  background: #0288d1;
  box-shadow: 1px 2px 5px rgba(21, 23, 26, 0.2);
  border-radius: 4px;
  padding: 9px;
  width: 287px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fdfdfd;
  line-height: 130%;
  cursor: pointer;
}
.edit-button img {
  margin-right: 51px;
}
</style>
